<template>
  <div>
    <sequential-entrance fromTop>
      <SellerHeader to="/seller/menu" headerText="Mis categorías" />
    </sequential-entrance>
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col cols="12" class="pt-0 pb-0 mt-3">
            <v-text-field
              label="Buscar categoria por nombre"
              v-model="filter"
              color="#00B8D9"
              flat
              background-color="#F4F5F7"
              class="app-input pt-0"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="categoriesLoading == false && categories.length">
          <v-col cols="12">
            <transition name="slide-fade">
              <v-container class="app-list">
                <v-row
                  v-for="(category, index) in categoryFilter"
                  class="align-center mb-5 rounded-lg blue lighten-4"
                  :key="index"
                  @click="goToProductCategoryId(category)"
                >
                  <v-col cols="7">
                    <p
                      class="
                          mb-0
                          font-weight-bold
                          text-color-soft-black
                        "
                    >
                      {{ category.name }}
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </transition>
          </v-col>
        </v-row>

        <!-- Skeleton Loader -->
        <v-row v-if="categoriesLoading">
          <v-container
            v-for="(skeleton, index) in skeletonCount"
            class="align-center d-flex justify-content-between rounded-lg mb-5"
            style="background-color: #FAFAFA!important"
            justify="between"
            :key="index"
          >
            <v-col cols="10">
              <b-skeleton width="60%"></b-skeleton>
              <b-skeleton width="90%"></b-skeleton>
            </v-col>

            <v-col cols="2" class="pl-0 text-center">
              <b-skeleton type="avatar"></b-skeleton>
            </v-col>
          </v-container>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getCategoriesBySeller } from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'
import _ from 'lodash'

export default {
  name: 'SellerCategoryList',

  data() {
    return {
      filter: null,
      categories: [],
      categoriesLoading: false,
      skeletonCount: [0, 1, 2, 3],
    }
  },
  computed: {
    categoryFilter() {
      return this.categories.filter(category =>
        this.filter
          ? category?.name.toLowerCase().includes(this.filter.toLowerCase())
          : category
      )
    },
  },
  methods: {
    goToProductCategoryId({ id: categoryId }) {
      localStorage.setItem('ticket-qr-category', categoryId)

      this.$router.push({
        name: 'Mis productos',
        params: { categoryId },
      })
    },
    async getCategories() {
      try {
        this.categoriesLoading = true

        const category = localStorage.getItem('selectedEvent')
        const data = await getCategoriesBySeller(category)

        this.categories = data.data

        this.categories.unshift({
          name: 'Todos los productos',
          id: -1,
        })

        this.categoriesLoading = false
      } catch (error) {
        this.$toast.error(ErrorMessages.COMMON)
      }
    },
  },
  components: {
    SellerHeader,
  },
  async mounted() {
    await this.getCategories()
  },
}
</script>

<style lang="scss">
.v-slide-group__prev {
  display: none !important;
}
</style>
